import React from 'react';
import {graphql} from 'gatsby';
import Default from 'gatsby-theme-carbon/src/templates/Default';
import {Row, Column} from 'gatsby-theme-carbon';
import {getStoryData} from '../util'
import StoryCard from '../components/story-card'
import { H3, H2 } from 'gatsby-theme-carbon/src/components/markdown';

export default function SuccessStories({pageContext, location, data}) {
	pageContext = {...pageContext, frontmatter:
		{
		  title: `Success Stories`
		}
	};
	let stories_data = []
	for (const item of data.allNodeSuccessStory.edges) {
		let node = item.node
		if(!node.title.includes('[SCHEMA]')){
			stories_data.push(getStoryData(node))
		}
	}
	return (
		<Default pageContext={pageContext} location={location}>
			<H2 className="no-anchor-heading">Learn more about Oregonians who work in the field of developmental disabilities services!</H2>
			<H3 className="no-anchor-heading" style={{marginTop: 0, marginBottom: 50}}>Through their success stories, learn how these professionals found a career working with people with intellectual and developmental disabilities.</H3>
			<p><a href="https://www.youtube.com/watch?v=WT3tcbbreLg" target="_blank">Watch our Direct Support Professional video</a></p>
			<p></p>
			<Row>
			{stories_data.map((story_data) => 
				<Column key={story_data.nid} colMd={4} colLg={4}>
					<StoryCard story_data={story_data} />
				</Column>)}
			</Row>

		</Default>
	  );
}

export const query = graphql`
{
	allNodeSuccessStory {
	  edges {
		node {
		  drupal_internal__nid
		  title
		  field_story_job_title
		  field_story_name
		  field_story_thumbnail {
			alt
		  }
		  relationships {
			field_story_career {
			  title
			}
			field_story_thumbnail {
			  localFile {
				url
				name
			  }
			}
		  }
		}
	  }
	}
  }
  
`