import React from 'react';
import {ArticleCard} from 'gatsby-theme-carbon';

export default function StoryCard({story_data}) {
	return (
		<ArticleCard
			title={`${story_data.field_story_name} - ${story_data.field_story_job_title}`}
			color="dark"
			author={story_data.career}
			href={story_data.path}
			>
			<span className="img" style={{backgroundImage: `url(${story_data.thumbnail})`}}>{story_data.thumbnail && story_data.thumbnail.length > 0 && <img src={story_data.thumbnail} alt={story_data.alt} />}</span>
		</ArticleCard>
	)
}
